import { ImageBlock } from 'components/ImageBlock';

import { useGatsbyImage } from 'hooks/useGatsbyImage';

import { Layout } from 'components/Layout';
import { Seo } from 'components/Seo';
import { Container, HeroContainer, Section, SectionImage } from 'components/Container';
import { SignupLink } from 'components/Links';
import { StatsBlock } from 'components/StatsBlock';
import { TrustPilot } from 'components/TrustPilot';
import { AnimatedContainer } from 'components/Container/AnimatedContainer';
import { PricingBlock } from 'components/PricingBlock';
import { SupportedBrokersBlock } from 'components/SupportedBrokersBlock';
import { Box } from 'components/Box';
import { FlexContainer } from 'components/FlexContainer';

import type { FC } from 'react';

const Index: FC = () => (
  <Layout hideFooterCta>
    {/* use contentful page called here */}
    <Seo />

    <AnimatedContainer animatedBackground="home">
      <HeroContainer padding={0} skipColoration background="transparent" manageLayout={false}>
        <Section center>
          <Box paddingBottom={6}>
            <h1>Be the smarter investor</h1>
            <FlexContainer direction="column" alignItems="center">
              <Box maxWidth="500px">
                <p>
                  Powerful portfolio tracking software that lets you check your investments in one
                  place with award-winning <strong>performance</strong>,{' '}
                  <strong>dividend tracking</strong> and <strong>tax reporting</strong>.
                </p>
              </Box>
            </FlexContainer>
            <br />

            <SignupLink
              asButton
              appearance="primary"
              containerBackground="positive"
              showArrowIcon
            />
          </Box>
        </Section>
        <SectionImage
          boxShadow={false}
          padding={3}
          center
          image={useGatsbyImage({
            name: 'index/hero',
            alt: "User Interface of Sharesight's portfolio management tool",
            loading: 'eager',
          })}
        />
      </HeroContainer>
    </AnimatedContainer>

    <SupportedBrokersBlock />
    <StatsBlock />
    <PricingBlock />

    <Container background="navyBlue">
      <SectionImage
        span={2}
        boxShadow={false}
        image={useGatsbyImage({
          name: 'index/no-fuss',
          alt: "User Interface of Sharesight's stock portfolio tracker with overlaid email field graphic",
        })}
      />
      <Section>
        <h2>
          No fuss, free sign up. <br /> No credit card needed
        </h2>
        <p>
          The only way to see true performance is to see it in context. Sign up for free without any
          commitment.
        </p>
        <br />
        <SignupLink asButton appearance="secondary" containerBackground="negative" showArrowIcon />
      </Section>
    </Container>

    <TrustPilot />

    <ImageBlock variation="featured" />

    <Container background="orangeHighlight">
      <Section>
        <h2>Start tracking your performance for free</h2>
        <p>
          Track up to 10 holdings. <br />
          No credit card needed.
        </p>
        <br />
        <SignupLink asButton appearance="primary" showArrowIcon />
      </Section>
      <SectionImage
        span={2}
        boxShadow={false}
        image={useGatsbyImage({
          name: 'shared-page-assets/localised/track-performance',
          alt: 'Zero dollar symbol with stock tickers surrounding it',
        })}
      />
    </Container>
  </Layout>
);

export default Index;
